// import Table from './../layout/table'
export default {
  install: (Vue) => {
    const Templates = require.context('./../layout/', true, /\.vue$/)
    const ssTemplates =  require.context("ss-pc/src/layout/", true, /\.vue$/);
    ssTemplates.keys().forEach((key) => {
      const Template = ssTemplates(key).default;
      Vue.component(Template.name, Template);
    });

    // Vue.component(Table.name, Table)
    Templates.keys().forEach(key => {
      const Template = Templates(key).default
      Vue.component(Template.name, Template)
    })
  }
}