 const courseLabelType = {
  1:{
    text:'必修课'
  },
  2:{
    text:'选修课'
  },
  3:{
    text:'公开课'
  }
}
const courseSource ={
  1:{
    type:'project',
    text:'项目',
    picUrl:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/project1.png'
  },
  2:{
    type:'course',
    text:'课程',
    picUrl:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/course2.png'
  },
  3:{
    type:'employ',
    text:'新员工',
    picUrl:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/employ3.png'
  },
  4:{
    type:'task',
    text:'任务',
    picUrl:'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/ss-pc/task4.png'
  },
}
export default {
  courseLabelType,
  courseSource
}