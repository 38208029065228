const urlName = '/ssusercenter/v1'

export default API => ({
    /**
  * 申请注销
  * 响应数据data：Boolean
  */
  cleanAccount (params) {
    return API({ url: `${urlName}/account/cleanAccount`, method: 'post', params });
  },

    /**
   * 注册
   * @param { password: 密码, account: 账号, authCode: 验证码 } param1
   * 响应数据data：  boolean   修改密码的结果，true 成功；false 失败
   */
  postAccountRegister ({  data }) {
    return API({ url: `/ssusercenter/v1/account/register`, method: 'post',  data });
  },
    /**
   * 忘记密码
   * @param { authCode password phone} 
   * 响应数据data：  
   */
  resetPassword ({  data }) {
    return API({ url: `${urlName}/account/resetPasswordNew`, method: 'post' , data });
  },
  branchApplyRecord({data}){
    return API({ url: `/ssusercenter/v1/branchApplyRecord/save`, method: 'post' , data });
  },
  frontendFrontendList() {
    return API({
      url: "/modular/admin/v1/frontend/frontendList",
      method: "post",
    });
  },
    // 获取信息
    findByCurr() {
      return API({
        url: "/ssusercenter/v1/branch/findByCurr",
      });
    },

})
