const urlName = '/standard-saas/v1'

export default API => ({
    /**
  * 培训计划列表
  */
    findPlanList ({params}) {
        return API({
            url: `${urlName}/taskUser/findPlanList`,
            params
        });
    },
    /**
  * 培训计划详情
  */
    findPlanDetail (params) {
        return API({
            url: `${urlName}/taskUser/findPlanDetail`,
            params
        });
    },
    // 满意度评价
    saveEveluate (data) {
        return API({
            url: `${urlName}/evaluate/save`,
            data,
            method:'post'
        });
    },
    // 查询满意度评价信息
    findByVo(params){
        return API({
            url: `${urlName}/evaluate/findByVo`,
            params,
        });
    },
    // 即将有多个证书过期
    expiringCertificate(params){
        return API({
            url: `/ssusercenter/v1/userOpenCertificate/expiringCertificate`,
            params,
        });
    }
})
