export default API => ({
    // 获取应急官网首页banner数据
    findByConditionAndDomain({
      data,
      params
    }) {
      return API({
        url: `/integration/v1/homepage/findByConditionAndDomain`,
        params,
        data,
        method:'post'
      });
    }
})
