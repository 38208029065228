<template>
  <div class="menuBox">
    <div class="content">
      <div class="logoBox">
        <img :src="titleLogo?.pcLogo || 'https://wd-appdownload.oss-cn-beijing.aliyuncs.com/emergency-platform-pc/logo.png'" v-if="titleLogo.showLogo"/>
        <span class="name" v-if="titleLogo.showTitle">{{titleLogo?.title || '安全与应急管理培训服务平台'}}</span>
      </div>
      <el-menu
        :mode="'horizontal'"
        active-text-color="#FFFFF"
        text-color="#FFFFFF"
        :default-active="activeNav"
        style="width: auto"
        @select="handleSelect"
      >
          <template>
            <el-menu-item index="/" v-if="!isOfficial">
              <span slot="title" class="name" title="首页">首页</span>
            </el-menu-item>
            <el-menu-item index="/home" v-else>
              <span slot="title" class="name" title="首页">首页</span>
            </el-menu-item>
            <el-menu-item index="/staff/train" v-if="isOfficial">
              <span slot="title" class="name" title="全员培训">全员培训</span>
            </el-menu-item>
            <el-menu-item index="/enterprise/train" v-if="isOfficial">
              <span slot="title" class="name" title="企业培训">企业培训</span>
            </el-menu-item>
            <el-menu-item index="/courseData">
              <span slot="title" class="name" title="课程中心">课程中心</span>
            </el-menu-item>
            <el-menu-item index="/learn/list" v-if="token && isUser || !isOfficial">
              <span slot="title" class="name" title="学习中心">学习中心</span>
            </el-menu-item>
          </template>
      </el-menu>
      <div class="userInfo-right">
        <!-- <div class="course-search-box" v-if="$route.path === '/course/list'">
          <p class="label">课程</p>
          <el-input
            v-model="courseSearchName"
            placeholder="请输入内容"
          ></el-input>
          <p class="btn" @click="courseSearch">搜索</p>
        </div> -->
        <template v-if="token">
          <el-dropdown trigger="click" class="user-dropdown">
            <span class="el-dropdown-link" @click="showUser = !showUser">
              <img
                class="headImage"
                :src="
                  subUserInfo?.headImage
                    ? subUserInfo.headImage
                    : require('../../../../assets/images/userL.png')
                "
                alt=""
              />
              <span>{{ subUserInfo?.name || subUserInfo?.nickname }}</span>
            </span>
            <el-dropdown-menu
              :append-to-body="false"
              slot="dropdown"
              class="account-dropdown-menu"
            >
              <div class="top">
                <img
                  class="headImage"
                  :src="
                    subUserInfo?.headImage ||
                    require('../../../../assets/images/userL.png')
                  "
                  alt=""
                />
                <p class="personal" @click="jump">个人中心</p>
                <p class="personal" @click="changeLdt" v-if="branchList && branchList.length && branchList.length>1">切换企业</p>
                <p class="currentCorporation">
                  当前企业：{{ branchName ||  UserBranchList?.name}}
                </p>
                <p class="adminBtn" v-if="isAdmin" @click="goAdmin">
                  切换到管理后台
                </p>
                <p class="adminBtn" v-if="isUser && isOfficial && token" @click="jumpStudy">
                  去学习
                </p>
              </div>
              <p class="line"></p>
              <div class="bottom-out" @click="dialogVisible.show = true">
                退出登录
              </div>
            </el-dropdown-menu>
          </el-dropdown>
        </template>
        <template v-else>
          <div class="handle-btn">
            <el-button type="text" @click="toRegister">注册</el-button>
            <el-dropdown @command="handleCommand">
              <span class="el-dropdown-link">
                登录<i class="el-icon-arrow-down el-icon--right"></i>
              </span>
              <el-dropdown-menu slot="dropdown">
                <el-dropdown-item command="govt" v-if="isOfficial">政府/协会管理者</el-dropdown-item>
                <el-dropdown-item command="ldt" v-if="isOfficial">企业管理者</el-dropdown-item>
                <el-dropdown-item command="user" >学员</el-dropdown-item>
              </el-dropdown-menu>
            </el-dropdown>
          </div>
        </template>
      </div>
    </div>
    <logOutDialog :dialogVisible="dialogVisible"></logOutDialog>
    <el-dialog
      title=""
      :visible.sync="branchListShow"
      :modal="false"
      width="400px"
      center
    >
      <div class="account-dialog">
        <p class="branchTitle">切换企业</p>
        <ul class="ct">
          <li
            v-for="item in branchList"
            :key="item.ucode"
            @click="primaryBranch(item.ucode)"
            :class="{ primary: primary === item.ucode, mt10: true }"
          >
            <span v-if="branchUcode == item.ucode" class="current">当前</span
            >{{ item.name }}
          </li>
        </ul>
      </div>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="setBranch">选好了</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import logOutDialog from "./logOutDialog";
import URL from "../../../../config/url";
import isOfficial from '@/utils/common';
import {branchDomain} from './../../../../utils/getBranchDomain'
import { getConfigureValue } from '@/utils/configure.js'
export default {
  components: { logOutDialog },
  computed: {
    subUserInfo() {
      return this.$store.state.subUserInfo;
    },
  },
  data() {
    return {
      titleLogo:null,//标题和logo
      isUser:localStorage.getItem('identity')=='user'?true:false,
      isOfficial,
      branchUcode:localStorage.getItem('branchUcode'),
      branchList:JSON.parse(localStorage.getItem('UserBranchList')),
      branchListShow:false,
      primary:'',
      dialogVisible: {
        show: false,
      },
      showUser: false,
      token: "",
      courseSearchName: "",
      branchName:localStorage.getItem('branchName'),
      UserBranchList:
        (localStorage.getItem("UserBranchList") &&
          JSON.parse(localStorage.getItem("UserBranchList"))[0]) ||
        null,
      activeNav: "/" + this.$route.path.split("/")[1],
      isAdmin: false,
      pageHeaderLogo: null,

    };
  },
  watch: {
    $route() {
     this.activeNav = this.$route.path;
    },
  },
  created() {
    this.titleLogo = getConfigureValue('yj_ssbase_configure') && JSON.parse(getConfigureValue('yj_ssbase_configure'));
    // let setTempConfigs =
    //   localStorage.getItem("setTempConfigs") &&
    //   JSON.parse(localStorage.getItem("setTempConfigs"));
    // console.log(setTempConfigs, "setTempConfigs===");
    // this.pageHeaderLogo =
    //   setTempConfigs.pageHeader && JSON.parse(setTempConfigs.pageHeader);
    this.token = localStorage.getItem("token");
    console.log(this.activeNav, "activeNav");

    this.token && this.getIsAdmin();
  },
  async mounted() {
    // setTimeout(() => {
    //   //刷新
    //   this.activeNav = sessionStorage.getItem("index") || this.activeNav;
    // }, 100);
    this.activeNav = this.$route.path;
    if(localStorage.getItem('token') && !localStorage.getItem('UserBranchList') && localStorage.getItem('identity')=='user'){
      this.getUserBranchList();
    }
  },
  methods: {
    async getUserBranchList() {
      const res = await this.$api.usercenter.getFindUserBranchList({});
      if (res.data && res.data.length) {
        
        this.branchList = res.data;
        localStorage.setItem('UserBranchList',JSON.stringify(res.data))
        if (res.data.length) {
          this.branchList.map(item=>{
            if(item.ucode==localStorage.getItem('branchUcode')){
              this.branchName = item.name;
              this.branchUcode = item.ucode;
              this.primary = item.ucode;
            }
          })
        }
      }
    },
    showBranchList () {
      this.branchListShow = !this.branchListShow
      this.primary = ''
    },
    async setBranch () {
      if (!this.primary) {
        this.showBranchList()
        return
      }
      this.branchList.forEach(async item => {
        if (item.ucode == this.primary) {
          this.branchUcode = item.ucode;
          this.branchName = item.name;
          localStorage.removeItem('branchDomain');
          localStorage.setItem('branchUcode',this.branchUcode)
          setTimeout(async () => {
            const result = await branchDomain();
            if(result?.pc){
              const address = window.location.protocol+"//"+result?.pc;
              window.open(`${address}?token=${localStorage.getItem('token')}`,'_self');
            }else{
              location.replace("/");
            }
          }, 500);
        }
      })
    },
    // 切换企业
    changeLdt(){
      this.branchListShow = true;
      this.getUserBranchList();
    },
    toRegister(){
      this.$router.push({
        path:'/feRegister',
      })
    },
    handleCommand(command){
      this.$router.push({
        path:'/login',
        query:{
          from:command
        }
      })
    },
    getIsAdmin() {
      this.$api.usercenter.getIsAdmin().then((res) => {
        this.isAdmin = res.data || false;
      });
    },
    courseSearch() {
      this.$store.commit("courseCenterSearchName", this.courseSearchName);
    },
    goAdmin() {
      window.open(
        URL.env
          ? `https://emergency-admin.${URL.env}.wdeduc.com?token=${localStorage.getItem('token')}`
          : `https://emergency-admin.wdeduc.com?token=${localStorage.getItem('token')}`
      ,'_self');
    },
    primaryBranch (branchUcode) {
        this.primary = branchUcode
    },
    async jump() {
      if(localStorage.getItem('identity')=='user' && isOfficial){
        const result = await branchDomain();
        if(result?.pc){
          const address = window.location.protocol+"//"+result?.pc;
          window.open(`${address}/personal?token=${localStorage.getItem('token')}`,'_self');
        }else{
          this.$router.push("/personal");
        }
        
      }else{
        this.$router.push("/personal");
      }
      this.showUser = false;
    },
    async handleSelect(keyPath) {
      if(isOfficial && (['/courseData','/learn/list'].includes(keyPath)) && localStorage.getItem('token') && localStorage.getItem('identity')=='user'){ //如果是官网
        const result = await branchDomain();
        if(result?.pc){
           const address = window.location.protocol+"//"+result?.pc;
          window.open(`${address}${keyPath}?token=${localStorage.getItem('token')}`,'_self');
        }else{
          if (keyPath === this.$route.path) {
            return false;
          }
          this.$router.push({
            path:keyPath
          });
        }
        
      }else{
        if (keyPath === this.$route.path) {
          return false;
        }
        this.$router.push({
          path:keyPath
        });
          console.log('------------------');
      }
    },
    async jumpStudy(){
      const result = await branchDomain();
      if(result?.pc){
        const address = window.location.protocol+"//"+result?.pc;
        window.open(`${address}/learn/list?token=${localStorage.getItem('token')}`,'_self');
      }else{
        this.$router.push({
          path:'/learn/list'
        })
      }
    },
  },
};
</script>

<style lang="scss" src="../../../../assets/css/menu.scss" scoped></style>
