/*
 * @Author: felixli
 * @Date: 2023-11-10 10:03:58
 * @Description:
 */
export default [
  {
    path: "/learn/list",
    name: "learnList",
    meta: {
      weight: 0,
      login: true,
    },
    component: () =>
      import(/* webpackChunkName: 'learnList' */ "./page/index.vue"),
  },{
    path: "/plan/detail",
    name: "planDetail",
    meta: {
      weight: 0,
      login: true,
    },
    component: () =>
      import(/* webpackChunkName: 'plan' */ "./page/planDetail.vue"),
  }
];
