export default API => ({
  courseListByDomain({
    params,
    data
  }) {
    return API({
      url: '/standard-saas/v1/task/open/courseListByDomain',
      method: 'post',
      params,
      data
    })
  },
  findAllByDomain({params,data}) {
    return API({
        url: `/standard-saas/v1/category/findAllByDomain` ,
        method:  'get',
        params,
        data
    });
  }
})
